


export const MessageNavList = [
    {
        navImg:require("@/views/Message/Img/person.png"),
        content:"我的好友",
        rightHide:true,
        path:"/myFriends"
    }
]
